import React, { ElementType } from 'react';
import styled from 'styled-components';
import { theme } from '@styles/theme';
import Link from 'next/link';
import ChevronIcon from '../../../assets/images/icons/chevron-right.svg';
import ContainImage from '@components/views/ContainImage';
import clsx from 'clsx';

export interface NewsListItemProps {
  title: string;
  as?: ElementType;
  href: string;
  date: string;
  thumbnail: string;
  onHover: (data: any) => void;
  isActive?: boolean;
}

const StyledWrapper = styled.li`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.neutral[2]};
  }
`;

const NewsListItem: React.FunctionComponent<NewsListItemProps> = (props) => {
  const { title, as = 'li', href, onHover, thumbnail, isActive } = props;

  return (
    <StyledWrapper
      as={as}
      className="relative cursor-pointer"
      onMouseEnter={() => onHover(props)}
    >
      <Link href={href} passHref>
        <a className="block font-extrabold text-neutral-9 text-lg py-24 md:py-44 group">
          <div className="overflow-hidden md:hidden mb-16">
            <ContainImage
              className=" transition-transform group-hover:scale-105"
              src={thumbnail}
            />
          </div>
          <div className="flex justify-between items-center">
            <div className="mr-auto flex flex-col transition-transform duration-300 relative">
              {title}
            </div>
            <span
              className={clsx(
                'ml-30 w-56 h-56 flex items-center justify-center rounded-full border border-neutral-9 transform transition-all duration-300 flex-shrink-0 group-hover:opacity-100 group-hover:-translate-x-1/2',
                { 'opacity-100': isActive, 'opacity-10': !isActive },
              )}
            >
              <ChevronIcon fill="currentColor" />
            </span>
          </div>
        </a>
      </Link>
    </StyledWrapper>
  );
};
export default NewsListItem;
