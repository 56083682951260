import React from 'react';
import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import SplideClass from '@splidejs/splide';

import '@splidejs/splide/dist/css/splide.min.css';

export interface MainNewsSlideProps {
  href: string;
  mainImage: ImageProps['src'] | string;
  hoverImage: ImageProps['src'] | string;
}

export interface MainNewsProps {
  slides: MainNewsSlideProps[];
  onSlideMoved: (index: number) => void;
  onSplideMounted?: (splide: SplideClass) => void;
}

const Slide = ({ href, hoverImage, mainImage }: MainNewsSlideProps) => {
  return (
    <Link href={href} passHref>
      <a className="group block relative min-h-[44.8rem]">
        {hoverImage && (
          <Image
            src={hoverImage}
            alt="current post img"
            layout="fill"
            className="group-hover:scale-100 object-cover transition-transform transform scale-105"
          />
        )}
        <Image
          src={mainImage}
          alt="main post img"
          layout="fill"
          className={clsx('object-cover transition-opacity', {
            'group-hover:opacity-0': !!hoverImage,
          })}
        />
        <div className="absolute bottom-0 right-0 bg-neutral-0 py-44 px-92 text-sm group-hover:bg-neutral-1 group-hover:text-neutral-9 transition-colors">
          Czytaj więcej
        </div>
      </a>
    </Link>
  );
};

const MainNews: React.FunctionComponent<MainNewsProps> = ({
  slides,
  onSlideMoved,
  onSplideMounted,
}) => {
  return (
    <Splide
      options={{
        pagination: false,
        arrows: false,
        type: 'fade',
        rewind: true,
        autoplay: true,
        speed: 600,
      }}
      onMoved={(_, index) => {
        onSlideMoved(index);
      }}
      onMounted={(splide) => {
        if (onSplideMounted) {
          onSplideMounted(splide);
        }
      }}
    >
      {slides.map(({ href, hoverImage, mainImage }) => (
        <SplideSlide key={href}>
          <Slide href={href} hoverImage={hoverImage} mainImage={mainImage} />
        </SplideSlide>
      ))}
    </Splide>
  );
};
export default MainNews;
