import React from 'react';
import { Tag } from 'src/services/api/types/IPosts';

export interface TagsProps {
  tags?: Tag[];
  title?: string;
}

const BasicTags: React.FunctionComponent<TagsProps> = ({ tags, title }) => {
  const text =
    tags && tags.length > 0
      ? tags.reduce(
          (total, tag, index) =>
            index < tags.length - 1
              ? `${total + tag.name} &bull; `
              : `${total + tag.name}`,
          '',
        )
      : '';

  return (
    <div className="text-xxs uppercase flex items-center">
      {!!title && <div className="mr-12 flex-shrink-0">{title}</div>}
      {!!text && (
        <div
          className="overflow-x-hidden whitespace-nowrap overflow-ellipsis text-neutral-4"
          dangerouslySetInnerHTML={{ __html: text }}
          title={text.replace(/&bull;/g, ', ')}
        />
      )}
    </div>
  );
};

export default BasicTags;
