import React, { useState } from 'react';
import { ChipProps } from '@components/views/Chip';
import { HeadingProps } from 'src/components/views/Heading';
import { IPostACF } from 'src/services/api/types/IPosts';
import PrimaryLayout from '@components/views/NewsSection/PrimaryLayout';
import ThreeColLayout from '@components/views/NewsSection/ThreeColLayout';
import { IButtonACF } from 'src/types/IButtonACF';
import { FacebookFeedsProps } from '../FacebookFeeds';

const offset = 6;
export interface NewsSectionProps {
  button: IButtonACF;
  variant?: 'primary' | 'three_col';
  chip?: ChipProps;
  heading?: HeadingProps;
  main_posts: {
    list: Array<{
      post: IPostACF;
    }>;
  };
  grid_posts: {
    posts: Array<{
      post: IPostACF;
    }>;
    count_of_visibility_posts: string;
  };
  list_posts: {
    posts: Array<{
      post: IPostACF;
    }>;
    count_of_visibility_posts: string;
  };
  category_as_tag?: boolean;
  facebook_feeds?: {
    data: FacebookFeedsProps['feeds'];
  };
}

const NewsSection: React.FunctionComponent<NewsSectionProps> = (props) => {
  const {
    button,
    chip,
    heading,
    main_posts,
    grid_posts,
    list_posts,
    variant,
    category_as_tag,
    facebook_feeds,
  } = props;

  const [newsToShow, setNewsToShow] = useState<number>(
    Number(grid_posts.count_of_visibility_posts) || 3,
  );
  const isShowMore = newsToShow < grid_posts.posts.length || false;
  const handleMoreClick = () =>
    setNewsToShow((prevState) => prevState + offset);
  const handleLessClick = () =>
    setNewsToShow((prevState) => prevState - offset);

  if (!variant || variant === 'primary') {
    return (
      <PrimaryLayout
        button={button}
        main_posts={main_posts}
        grid_posts={grid_posts}
        isShowMore={isShowMore}
        newsToShow={newsToShow}
        chip={chip}
        heading={heading}
        moreClick={handleMoreClick}
        lessClick={handleLessClick}
        categoryAsTag={category_as_tag}
        facebook_feeds={facebook_feeds}
      />
    );
  }

  if (variant === 'three_col') {
    return (
      <ThreeColLayout
        button={button}
        main_posts={main_posts}
        grid_posts={grid_posts}
        list_posts={list_posts}
        newsToShow={newsToShow}
        heading={heading}
        categoryAsTag={category_as_tag}
      />
    );
  }

  return <></>;
};
export default NewsSection;
