import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';

export interface FacebookFeedsProps {
  feeds: Array<{
    message: string;
    full_picture: string;
    created_time: string;
    id: string;
    permalink_url: string;
  }>;
}

const FacebookFeeds = ({ feeds }: FacebookFeedsProps) => {
  return (
    <>
      {feeds && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-32">
          {feeds.map((feed, key) => {
            return (
              <Link key={key} href={feed.permalink_url}>
                <a
                  target={'_blank'}
                  rel="nofollow noopener noreferrer"
                  className="flex group gap-16 items-center"
                >
                  {feed.full_picture && (
                    <div className="relative flex-shrink-0 h-54">
                      <Image
                        src={`${feed.full_picture}`}
                        width={96}
                        height={54}
                        alt=""
                        objectFit="cover"
                        objectPosition="top"
                        className="group-hover:scale-105 transition-transform duration-300"
                      />
                    </div>
                  )}
                  <div>
                    <div className="text-xxs text-neutral-6">
                      {dayjs(feed.created_time).format('DD.MM / YYYY')}
                    </div>
                    {feed.message && (
                      <div
                        className="text-neutral-4 text-sm lg:text-xs line-clamp-2 mt-8"
                        title={feed.message}
                      >
                        {feed.message}
                      </div>
                    )}
                  </div>
                </a>
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};

export default FacebookFeeds;
