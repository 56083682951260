import React from 'react';
import dayjs from 'dayjs';

export interface DateProps {
  date: string;
}

const Date: React.FunctionComponent<DateProps> = ({ date }) => {
  return (
    <div className="text-xxs text-neutral-0">
      {dayjs(date).format('DD.MM / YYYY')}
    </div>
  );
};
export default Date;
