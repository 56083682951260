import Button from '@components/views/Button';
import Chip, { ChipProps } from '@components/views/Chip';
import Container from '@components/containers/Container';
import FacebookFeeds from '@components/containers/FacebookFeeds';
import Heading, { HeadingProps } from '@components/views/Heading';
import MainNews from '@components/views/MainNews';
import NewsBox from '@components/views/NewsBox';
import NewsListItem from '@components/views/NewsListItem';
import React, { useState } from 'react';
import Separator from '@components/views/Separator';
import { IButtonACF } from 'src/types/IButtonACF';
import { NewsSectionProps } from '@components/containers/NewsSection';
import { Tag } from 'src/services/api/types/IPosts';
import omit from 'lodash/omit';
import { useOptionsQuery } from 'src/services/api/queries/hooks/useOptionsQuery';
import Splide from '@splidejs/splide';
import useWindowSize from 'src/hooks/useWindowSize';

interface PrimaryLayoutProps {
  button: IButtonACF;
  chip?: ChipProps;
  heading?: HeadingProps;
  main_posts: NewsSectionProps['main_posts'];
  grid_posts: NewsSectionProps['grid_posts'];
  isShowMore: boolean;
  newsToShow: number;
  moreClick: () => void;
  lessClick: () => void;
  categoryAsTag?: boolean;
  facebook_feeds?: NewsSectionProps['facebook_feeds'];
}

const PrimaryLayout: React.FunctionComponent<PrimaryLayoutProps> = (props) => {
  const {
    button,
    chip,
    heading,
    main_posts,
    grid_posts,
    isShowMore,
    newsToShow,
    moreClick,
    lessClick,
    categoryAsTag,
    facebook_feeds,
  } = props;
  const [currentPost, setCurrentPost] = useState(0);
  const [splideInstance, setSplideInstance] = useState<Splide | null>(null);
  const { data } = useOptionsQuery();
  const { width } = useWindowSize();

  const fb =
    data?.socials && data.socials.find(({ link }) => link.includes('facebook'));

  return (
    <section className="mt-40 lg:mt-96">
      <Container>
        {chip && (
          <div className="text-center text-xxs">
            <Chip {...chip}>{chip?.title}</Chip>
          </div>
        )}
        {heading && (
          <div className="text-neutral-900 mt-8 md:mt-0 text-4xl md:text-7xl font-extrabold text-center mb-64">
            <Heading {...heading} />
          </div>
        )}
        <div className="flex flex-col md:grid md:grid-cols-2 gap-28">
          {width && width > 767 && main_posts?.list && (
            <MainNews
              slides={main_posts.list.map(({ post }) => ({
                href: post.url,
                mainImage: post.thumbnail,
                hoverImage: post.secondary_image,
              }))}
              onSlideMoved={(index) => {
                setCurrentPost(index);
              }}
              onSplideMounted={(splide) => {
                setSplideInstance(splide);
              }}
            />
          )}
          {main_posts?.list && (
            <ul className="newsList">
              {main_posts.list.map((item, index) => {
                const { post } = item;

                return (
                  <NewsListItem
                    key={`post-list-${post.ID}`}
                    {...post}
                    title={post.post_title}
                    date={post.post_date}
                    href={post.url}
                    onHover={() => {
                      if (splideInstance) {
                        splideInstance.go(index);
                      }
                    }}
                    isActive={
                      width && width > 767 ? currentPost === index : false
                    }
                  />
                );
              })}
            </ul>
          )}
        </div>
        <Separator />
        {grid_posts?.posts && (
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-32 md:gap-64 lg:gap-32">
            <div className="lg:col-span-3">
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-32 mb-32 lg:mb-86">
                {grid_posts.posts.slice(0, newsToShow).map((newsBox, index) => {
                  const { post } = newsBox;
                  return (
                    <NewsBox
                      key={`news-box-${post.ID}-${index}`}
                      href={post.url || post.link || ''}
                      title={post.post_title}
                      img={post.thumbnail}
                      category={categoryAsTag ? '' : post.terms[0]?.name}
                      excerpt={post.excerpt}
                      tags={
                        categoryAsTag && post.terms && post.terms[0]
                          ? ([{ name: post.terms[0].name }] as Tag[])
                          : post.tags
                      }
                    />
                  );
                })}
              </div>
              <div className="flex flex-wrap gap-32 items-center justify-end">
                {Number(grid_posts.count_of_visibility_posts || 3) <
                  grid_posts.posts.length && (
                  <Button
                    variant="SECONDARY"
                    onClick={isShowMore ? moreClick : lessClick}
                  >
                    {isShowMore ? 'Pokaż więcej' : 'Pokaż mniej'}
                  </Button>
                )}
                {button?.url && button?.title && (
                  <Button
                    {...omit(button, 'title', 'url')}
                    href={button.url}
                    variant="GHOST"
                  >
                    {button.title}
                  </Button>
                )}
              </div>
            </div>
            {facebook_feeds && facebook_feeds.data && (
              <div className="overflow-hidden">
                <Heading
                  title="Facebook"
                  level="h3"
                  className="mb-24 text-2xl font-extrabold"
                />
                <div className="mb-24">
                  <FacebookFeeds
                    feeds={facebook_feeds?.data.slice(0, newsToShow)}
                  />
                </div>
                {fb?.link && (
                  <div className="md:hidden">
                    <Button variant="GHOST" noPadding href={fb.link}>
                      Zobacz na FB
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Container>
    </section>
  );
};
export default PrimaryLayout;
