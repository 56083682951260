import React, { useLayoutEffect, useRef, useState } from 'react';
import Link from 'next/link';
import BasicTags from '../BasicTags';
import ContainImage from '../ContainImage';
import clsx from 'clsx';
import Date from '@components/views/Date';
import VideoIcon from '../../../assets/images/icons/video.svg';
import { Tag } from 'src/services/api/types/IPosts';
import { ImageProps } from 'next/image';

export interface NewsBoxProps {
  img: ImageProps['src'] | string;
  title: string;
  category?: string;
  tags?: Tag[];
  excerpt: string;
  href: string;
  bigTitle?: boolean;
  noImage?: boolean;
  hideCategory?: boolean;
  horizontal?: boolean;
  date?: string;
  meta_data?: { is_video_in_content: boolean };
  variant?: 'reversed';
  height?: string;
  tagsSpace?: boolean;
}

const LINE_HEIGHT = 24;
const MAX_HEIGHT = 4 * LINE_HEIGHT;

const NewsBox: React.FunctionComponent<NewsBoxProps> = ({
  img,
  title,
  category,
  tags,
  excerpt,
  href,
  bigTitle,
  noImage,
  horizontal,
  date,
  meta_data,
  variant,
  height = '192px',
  tagsSpace = true,
}) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [lines, setLines] = useState(1);

  useLayoutEffect(() => {
    const titleHeight = titleRef.current?.getBoundingClientRect().height;
    if (titleHeight) {
      setLines((MAX_HEIGHT - titleHeight) / LINE_HEIGHT);
    }
  }, [titleRef]);

  return (
    <Link href={href?.replace('https://wsc-frontend.vercel.app', '') || ''}>
      <a
        className={clsx('group block overflow-hidden', {
          'grid sm:grid-cols-2 md:grid-cols-[22.4rem_1fr] items-center sm:gap-32':
            horizontal,
        })}
      >
        {img && !noImage && (
          <div
            className={clsx('relative overflow-hidden', {
              'mb-12': !horizontal,
            })}
          >
            <ContainImage
              src={img}
              className="group-hover:scale-105 object-cover transition-transform duration-300"
              height={height}
            />
            <div className="group-hover:opacity-10 bg-neutral-9 transition-opacity duration-300 opacity-0 absolute top-0 left-0 w-full h-full" />
            {date && (
              <div className="absolute left-12 bottom-12">
                <Date date={date} />
              </div>
            )}
            {meta_data?.is_video_in_content && (
              <>
                <div className="opacity-40 absolute top-0 left-0 w-full h-full bg-neutral-2" />
                <VideoIcon className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
              </>
            )}
          </div>
        )}
        <div className="flex flex-col">
          {(!!category || (tags && tags.length > 0)) && (
            <div
              className={clsx({
                'order-2': variant === 'reversed',
                'mb-8': variant !== 'reversed',
              })}
            >
              <BasicTags title={category} tags={tags} />
            </div>
          )}
          {!horizontal &&
            !category &&
            tags?.length === 0 &&
            !bigTitle &&
            tagsSpace && <div className="h-28" />}
          <h3
            dangerouslySetInnerHTML={{ __html: title }}
            className={clsx('font-extrabold text-neutral-9 line-clamp-3', {
              'text-4xl': bigTitle,
              'group-hover:underline order-1 mb-16': variant === 'reversed',
            })}
            ref={titleRef}
          />
          {excerpt && (
            <div
              dangerouslySetInnerHTML={{ __html: excerpt }}
              className={`text-neutral-4 text-sm order-3 mt-8 line-clamp-${
                bigTitle ? 3 : lines
              }`}
            />
          )}
        </div>
      </a>
    </Link>
  );
};

export default NewsBox;
