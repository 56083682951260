import clsx, { ClassDictionary } from 'clsx';
import { HTMLAttributes } from 'react';

interface SeparatorProps extends HTMLAttributes<HTMLDivElement> {
  variant?: 'HORIZONTAL' | 'VERTICAL';
  classDictionary?: ClassDictionary;
}

const Separator = ({
  variant = 'HORIZONTAL',
  classDictionary = {},
  ...attrs
}: SeparatorProps) => {
  if (variant === 'VERTICAL') {
    return (
      <div
        className={clsx('h-15 w-1 bg-neutral-3', classDictionary)}
        {...attrs}
      />
    );
  }
  return <div className="w-full h-1 bg-neutral-2 my-56" />;
};
export default Separator;
