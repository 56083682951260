import React from 'react';
import Heading, { HeadingProps } from '@components/views/Heading';
import { NewsSectionProps } from '@components/containers/NewsSection';
import Container from '@components/containers/Container';
import NewsBox from '@components/views/NewsBox';
import Button from '@components/views/Button';
import { IButtonACF } from 'src/types/IButtonACF';
import omit from 'lodash/omit';
import { Tag } from 'src/services/api/types/IPosts';

export interface ThreeColLayoutProps {
  button: IButtonACF;
  heading?: HeadingProps;
  main_posts: NewsSectionProps['main_posts'];
  grid_posts: NewsSectionProps['grid_posts'];
  list_posts: NewsSectionProps['list_posts'];
  newsToShow: number;
  categoryAsTag?: boolean;
}

const ThreeColLayout = ({
  grid_posts,
  main_posts,
  list_posts,
  heading,
  button,
  categoryAsTag,
}: ThreeColLayoutProps) => {
  return (
    <Container className="mb-96">
      {heading && (
        <div className="text-neutral-900 text-4xl font-extrabold mb-56">
          <Heading {...heading} />
        </div>
      )}
      <div className="grid md:grid-cols-2 gap-32 lg:gap-64">
        <div className="grid gap-32 lg:gap-64">
          {main_posts?.list &&
            main_posts.list
              .filter((post) => post)
              .map((newsBox, index) => {
                const { post } = newsBox;
                return (
                  <NewsBox
                    key={`news-box-main-${post.ID}-${index}`}
                    href={post.url}
                    title={post.post_title}
                    img={post.thumbnail}
                    excerpt={post.excerpt}
                    bigTitle
                    height="38rem"
                    tagsSpace={false}
                  />
                );
              })}
          {button?.url && button?.title && (
            <div className="mt-auto hidden md:block">
              <Button
                {...omit(button, 'title', 'url')}
                href={button.url}
                variant="GHOST"
              >
                {button.title}
              </Button>
            </div>
          )}
        </div>
        <div className="grid md:grid-cols-2 gap-32 lg:gap-64">
          {grid_posts?.posts && (
            <div className="grid gap-32 lg:gap-56">
              {grid_posts.posts
                .filter(({ post }) => post)
                .map((newsBox, index) => {
                  const { post } = newsBox;

                  return (
                    <NewsBox
                      key={`news-box-grid-${post.ID}-${index}`}
                      href={post.url}
                      title={post.post_title}
                      img={post.thumbnail}
                      category={categoryAsTag ? '' : post.terms?.[0]?.name}
                      excerpt={post.excerpt}
                      tags={
                        categoryAsTag && post.terms[0]
                          ? ([{ name: post.terms[0]?.name }] as Tag[])
                          : post.tags
                      }
                      hideCategory
                      date={post.post_date}
                      tagsSpace={false}
                    />
                  );
                })}
            </div>
          )}
          {list_posts?.posts && (
            <div>
              {list_posts.posts
                .filter(({ post }) => post)
                .map((newsBox, index) => {
                  const { post } = newsBox;

                  return (
                    <div key={`news-box-list-${post.ID}-${index}`}>
                      <NewsBox
                        href={post.url}
                        title={post.post_title}
                        img={post.thumbnail}
                        category={post.terms?.[0]?.name}
                        excerpt={post.excerpt}
                        tags={post.tags}
                        noImage
                        hideCategory
                        variant={'reversed'}
                        tagsSpace={false}
                      />
                      {index < list_posts.posts.length - 1 && (
                        <div className="h-1 w-full bg-neutral-2 my-32" />
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        {button?.url && button?.title && (
          <div className="md:hidden">
            <Button
              {...omit(button, 'title', 'url')}
              href={button.url}
              variant="GHOST"
            >
              {button.title}
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ThreeColLayout;
